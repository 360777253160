import React, { useState }  from 'react';
import NavLink from '../components/NavLink';
import {
   Grid,
   Typography,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Paper,
   Box,
   Button,
   CircularProgress, // Button loading indicator
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Analytics = ({ name='Complete Analytics', data, viewerDetails, setAnalyticView, global=true }) => {
  
   const [isExporting, setIsExporting] = useState(false); // State for loading
   const [exportComplete, setExportComplete] = useState(false); // State for download complete

   // Function to convert data to CSV format and trigger download
   const exportToCSV = () => {
       setIsExporting(true); // Setting loading state

       // CSV Headers
       const headers = global
           ? ['STP', 'Company', 'Location', 'Day', 'Date', 'Time']
           : ['Company', 'Location', 'Day', 'Date', 'Time'];

       // Function to parse date into Day, Date, and Time
       const parseDate = (dateString) => {
           const dateObj = new Date(dateString);
           const day = dateObj.toLocaleDateString('en-US', { weekday: 'long' });
           const date = dateObj.toLocaleDateString('en-US', {
               year: 'numeric',
               month: 'long',
               day: 'numeric',
           }).replace(',', '');
           const time = dateObj.toLocaleTimeString('en-US');
          
           return { day, date, time };
          
       };

       // Mapping viewerDetails data to an array of CSV rows
       const rows = viewerDetails.map((detail) => {
           const { day, date, time } = parseDate(detail.date);
           return global
               ? [detail.stp, detail.company, detail.location, day, date, time]
               : [detail.company, detail.location, day, date, time];
       });

       // Combining the headers and rows into a single CSV string
       const csvContent = [
           headers.join(','), // Adding headers as the first row
           ...rows.map((row) => row.join(',')), // Adding rows
       ].join('\n'); // Separating each row with a newline

       // Creating a blob from the CSV string
       const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

       // Creating a temporary link element to trigger the download
       const link = document.createElement('a');
       link.href = URL.createObjectURL(blob);
       link.setAttribute('download', 'viewer_details.csv'); // Setting the file name

       document.body.appendChild(link);
       link.click(); // Triggering the download
       document.body.removeChild(link);

        // Setting download complete state
        setIsExporting(false);
        setExportComplete(true);
        // Resetting export complete state after a delay
        setTimeout(() => setExportComplete(false), 3000);
   };
  
   return (
       <Box border='1px solid black' borderRadius='3px'>
           <Box onClick={() => global ? null : setAnalyticView(false)} color='white.main' bgcolor='black.main' sx={{ borderTopLeftRadius: '3px', borderTopRightRadius: '3px', display:"flex" }} justifyContent="space-between" alignItems="center" py={2} px={4} mb={4}>
               <Typography pr={2} variant='bold_analytics'>{name}</Typography>
               {
                   global ?
                       <NavLink to={`/listings`}><Typography variant='bold_analytics' sx={{ textDecoration: 'none !important' }}>BACK</Typography></NavLink>:    <Typography variant='bold_analytics' sx={{ textDecoration: 'none !important', cursor: 'pointer' }}>BACK</Typography>
               }
           </Box>
           <Box pt={2} pb={4} px={4} display="flex" justifyContent="space-between" alignItems="center">
               <Typography variant='bold_analytics'>Total Scans: {viewerDetails.length}</Typography>
               {/* CSV Export Button */}
               <Button variant="secondary" onClick={exportToCSV} disabled={isExporting} startIcon={isExporting ? <CircularProgress size={20} /> : null} sx={{ width: 'auto', paddingLeft:"20px", paddingRight:"20px" }}>
                   {isExporting ? 'Exporting...' : exportComplete ? 'Download Complete' : 'Export CSV'}
               </Button>
           </Box>
           {
               viewerDetails.length > 0 ?
                   <Grid container={true} pb={6} px={4}>
                       <Grid item mobile={12} md={6}>
                           <Typography variant='bold_analytics' mb={4} gutterBottom>Viewer Details</Typography>
                           <Box height={450} sx={{ overflow: 'scroll' }}>
                               <TableContainer component={Paper}>
                                   <Table>
                                       <TableHead>
                                           <TableRow>
                                               {
                                                   global ?
                                                       <TableCell>STP</TableCell>
                                                       :null
                                               }
                                               <TableCell>Company</TableCell>
                                               <TableCell>Location</TableCell>
                                               <TableCell>Date</TableCell>
                                           </TableRow>
                                       </TableHead>
                                       <TableBody>
                                           {viewerDetails.map((detail, index) => (
                                               <TableRow key={index}>
                                                   {
                                                       global ?
                                                           <TableCell>{detail.stp}</TableCell>
                                                           :null
                                                   }
                                                   <TableCell>{detail.company}</TableCell>
                                                   <TableCell>{detail.location}</TableCell>
                                                   <TableCell><Typography sx={{
                                                       whiteSpace: 'nowrap',
                                                       overflow: 'hidden'
                                                   }}>{detail.date}</Typography></TableCell>
                                               </TableRow>
                                           ))}
                                       </TableBody>
                                   </Table>
                               </TableContainer>
                           </Box>
                       </Grid>
                       <Grid py={[4, 4, 4, 0]} item mobile={12} md={6} height={450}>
                           <Typography variant='bold_analytics' mb={4} gutterBottom>Scans Over Time</Typography>
                           <Box py={2}></Box>
                           <ResponsiveContainer width="100%" height="100%">
                               <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                   <CartesianGrid strokeDasharray="3 3" />
                                   <XAxis dataKey="name" />
                                   <YAxis />
                                   <Tooltip />
                                   <Legend />
                                   <Bar dataKey="SAB" fill="#8884d8" />
                                   <Bar dataKey="SABM" fill="#8dd1e1" />
                                   <Bar dataKey="HEINEKEN" fill="#82ca9d" />
                                   <Bar dataKey="RETAILER" fill="#ffc658" />
                                   <Bar dataKey="OTHER" fill="#ff7300" />
                                   <Bar dataKey="N/A" fill="#e30332" />
                               </BarChart>
                           </ResponsiveContainer>
                       </Grid>
                   </Grid>
                   : null
           }
       </Box>
   );
};

export default Analytics;
